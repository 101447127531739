import { render, staticRenderFns } from "./sentItems.vue?vue&type=template&id=206de868&scoped=true&"
import script from "./sentItems.vue?vue&type=script&lang=js&"
export * from "./sentItems.vue?vue&type=script&lang=js&"
import style0 from "./sentItems.vue?vue&type=style&index=0&id=206de868&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "206de868",
  null
  
)

export default component.exports