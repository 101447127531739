<template>
	<div class="chat-window">
		<div class="chat-title">
			<p>已发邮件</p>
			<p>{{total}}</p>
		</div>
		<div class="chat-container">
			<div class="table-list">
				<div class="top-button">
					<!-- <el-button round size="small" @click="$router.go(-1)">{{ '<<' }}
					</el-button> -->
					<el-button round size="small" @click="handleDelete">删除
					</el-button>
					<!-- <el-button round
                               size="small"
                               @click="shiftDelete">彻底删除</el-button> -->
					<!-- <el-button round
                               size="small"
                               @click="handleTranspond">转发</el-button> -->
				</div>
				<el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange"
					@row-click="openDetails" :row-key="getKey" ref="table" border>
					<el-table-column type="selection" align="center" reserve-selection width="40"></el-table-column>
					<el-table-column label="收件人" align="center" prop="receiveMemberMail">
						<template slot-scope="scope">{{
                            scope.row.receiveUsername
                            }}
						</template>
					</el-table-column>
					<el-table-column label="主题" align="center" prop="subject">
						<template slot-scope="scope"><span class="pointer"
								@click="lookView(scope.row)">{{ scope.row.subject }}</span>
						</template>
					</el-table-column>
					<el-table-column label="时间" align="center" prop="sendTime">
						<template slot-scope="scope">{{ scope.row.sendTime }}</template>
					</el-table-column>
					<el-table-column label="状态" align="center" prop="status">
						<template slot-scope="scope">
							<span v-if="scope.row.sendStatus == 1" style="color: #7b7d80">成功</span>
							<span v-if="scope.row.sendStatus != 1" style="color: #ff6632">失败</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="操作" align="center" prop="status">
                      <template slot-scope="scope">
                        <el-button type="text" @click="handleTranspond(scope.row)"
                          >转发</el-button
                        >
                        <span style="color:#409EFF;"> | </span>
                        <el-button type="text"  @click="lookView(scope.row)">详情</el-button>
                      </template>
                    </el-table-column> -->
					<!-- <el-table-column label="操作" align="center" prop="status">
                      <template slot-scope="scope">
                        <span  style="color: #ff6632" @click="lookView(scope.row)">详情</span>
                      </template>
                    </el-table-column> -->
				</el-table>
			</div>
			<div class="submit">
				<div class="submit-button">
					<!-- <el-button round size="small" @click="$router.go(-1)">{{ '<<' }}
					</el-button> -->
					<el-button round size="small" @click="handleDelete">删除</el-button>
					<!-- <el-button round size="small" @click="shiftDelete"
                      >彻底删除</el-button
                    > -->
					<!-- <el-button round
                               size="small"
                               @click="handleTranspond">转发</el-button> -->
				</div>
				<div v-if="tableData.length > 0" class="pagination-container">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						layout="total,prev, pager, next" :page-size="listQuery.pageSize" :current-page.sync="listQuery.pageNum"
						:total="total">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		// 已发邮件
		name: "sentItems",
		components: {},
		data() {
			return {
				total: 0,
				listQuery: {
					pageNo: 1,
					pageSize: 10,
				},
				loginMsg: {},
				model: {},
				multipleSelection: [],
				tableData: [],
			};
		},
		created() {
			if (localStorage.getItem("loginMsg")) {
				this.loginMsg = JSON.parse(localStorage.getItem("loginMsg"));
			}
			this.getEmailList();
		},
		methods: {
			getKey(row) {
				return row.id;
			},
			clearData() {
				if (this.$route.query.isClear) {
					this.$confirm(
						"您要清空" + this.$route.query.name + "吗？",
						"清空" + name, {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							center: true,
							customClass: "msgBox",
						}
					).then(() => {
						this.shiftDelete();
					});
				}
			},
			// 查看邮件详情
			lookView(val) {
				this.$router.push({
					path: './emailDetail',
					query: {
						id: val.mailId,
						title: '已发邮件'
					}
				})
			},
			// 跳转邮件详情信息
			openDetails(row) {
				// this.$router.push({ path: 'sentItemsTranspond', query: { params: row } })
			},
			// 删除
			handleDelete() {
				if (this.multipleSelection.length == 0) {
					this.$message({
						message: "请先选择您要删除的邮件",
						type: "warning",
					});
					return;
				}
				let idArr = [];
				this.multipleSelection.forEach((item) => {
					idArr.push(JSON.parse(JSON.stringify(item.mailId)));
				});
				let ids = idArr.join(",");
				this.$api.email
					.batchUpdateEmail({
						ids,
						type: 2,
						sourceType: 0
					})
					.then((res) => {
						this.getEmailList();
						this.$message({
							message: "删除邮件成功",
							type: "success",
						});
						this.$emit('sendOk')
					});
			},
			// 彻底删除
			shiftDelete() {
				if (this.multipleSelection.length == 0) {
					this.$message({
						message: "请先选择您要彻底删除的邮件",
						type: "warning",
					});
					return;
				}
				let idArr = [];
				this.multipleSelection.forEach((item) => {
					idArr.push(JSON.parse(JSON.stringify(item.mailId)));
				});
				let ids = idArr.join(",");
				this.$api.email.batchDeleteEmail({
					ids
				}).then((res) => {
					this.getEmailList();
					this.$refs.table.clearSelection();
					this.$message({
						message: "彻底删除邮件成功",
						type: "success",
					});
					this.$emit('sendOk')
				});
			},
			//获取邮件列表
			getEmailList(callBack) {
				let params = this.listQuery;
				params.memberId = this.loginMsg.memberId;
				params.mailType = 0;
				this.$api.email
					.emailList(params)
					.then((res) => {
						console.log("邮箱列表", res);
						this.tableData = res.result.records;
						this.tableData.forEach((item) => {
							this.$set(item, "id", JSON.stringify(item.mailId));
						});
						this.total = res.result.total;
						this.$emit("updateNum", this.total);
						if (callBack) {
							callBack();
						}
					})
					.catch((err) => {
						this.$message.error(err);
					});
			},
			// 转发
			handleTranspond(val) {
				// this.$router.push({ path: 'sentItemsTranspond' })
				this.$router.push({
					path: "sendEmails",
					query: {
						model: JSON.stringify(val)
					},
				});
			},
			// 表格选择
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 每页页数变化
			handleSizeChange(val) {
				this.listQuery.pageSize = val;
				this.getEmailList();
			},
			// 当前页变化
			handleCurrentChange(val) {
				this.listQuery.pageNo = val;
				this.getEmailList();
			},
			//前往邮件详情
			goDetail(val) {
				console.log(val)
			}
		},
	};
</script>

<style scoped>
	.chat-window {
		min-width: 506px;
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		background: #ffffff;
		border-left: 1px solid #dcdfe6;
		position: relative;
	}

	.chat-window .chat-title {
		display: flex;
		align-items: center;
		height: 60px;
		font-size: 18px;
		color: #313233;
		letter-spacing: 2px;
		padding: 0 20px;
		background: #ffffff;
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
	}

	.chat-window .chat-title p:nth-child(2) {
		font-size: 18px;
		color: #7b7d80;
		margin-left: 8px;
	}

	.chat-window .chat-container {
		min-height: 540px;
		height: 100%;
		width: 100%;
		/* padding-top: 20px; */
		box-sizing: border-box;
		border-bottom: 1px solid #dcdfe6;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		/* overflow-x: hidden;
        overflow-y: auto; */
		/* position: relative; */
	}

	.top-button {
		height: 48px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
	}

	.top-button button {
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.top-button button:nth-child(2) {
		width: 84px;
	}

	.submit {
		width: 100%;
		height: 68px;
		padding-left: 10px;
		padding-right: 20px;
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		/* position: absolute;
        bottom: 0; */
		border-top: 1px solid #dcdfe6;
	}

	.submit-button {
		display: flex;
		flex-direction: row;
	}

	.submit button {
		width: 56px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.submit button:nth-child(2) {
		width: 84px;
	}

	.pointer {
		cursor: pointer;
	}
</style>